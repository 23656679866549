:root {
	--primary: #22c67b;
	--secondary: #25cfd2;
	--text-color: #797C86;
	--purple: #0A0A0A;
}

html {
	scroll-behavior: smooth !important;
}

img {
	display: block;
	line-height: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.bg-landing a:not([class*="font"], [class*="__variable"], [class*="className"]),
.bg-landing p:not([class*="font"], [class*="__variable"], [class*="className"]),
.bg-landing span:not([class*="font"], [class*="__variable"], [class*="className"]),
.bg-landing li:not([class*="font"], [class*="__variable"], [class*="className"]) {
	@apply font-normal
}

.bunya-font {
	font-family: 'Bunya PERSONAL'
}

.mulish-font {
	font-family: "Mulish"
}

.slider-custom {
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 4px;
	background: transparent;
	outline: none;
}

.slider-custom::-webkit-slider-runnable-track {
	background: linear-gradient(
			to right,
			#db2b4a 0%,
			#db2b4a var(--slider-progress),
			rgba(255, 255, 255, 0.1) var(--slider-progress),
			rgba(255, 255, 255, 0.1) 100%
	);
	border-radius: 50px;
	height: 4px;
}

.slider-custom::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	background: #db2b4a;
	border: 4px solid #fff;
	border-radius: 100%;
	cursor: pointer;
	margin-top: -8px;
}

.slider-custom::-moz-range-track {
	background: linear-gradient(
			to right,
			#db2b4a 0%,
			#db2b4a var(--slider-progress),
			rgba(255, 255, 255, 0.19) var(--slider-progress),
			rgba(255, 255, 255, 0.1) 100%
	);
	border-radius: 50px;
	height: 4px;
}

.slider-custom::-moz-range-thumb {
	width: 20px;
	height: 20px;
	background: #db2b4a;
	border: 4px solid #fff;
	border-radius: 100%;
	cursor: pointer;
}

.slider-custom::-moz-focus-outer {
	border: 0;
}

input[type=number] {
	-moz-appearance: textfield;
	appearance: textfield
}

.bg-landing {
	background: url('/images/landing/bg-hero-1-new.png') #070102 top center no-repeat;
	background-size: cover;
}

.bg-bill {
	background: url('/images/landing/bg-bill-new.png') center center no-repeat;
	background-size: cover;
}

.bg-ideas {
	background-image: url('/images/landing/bg-ideas.png');
	background-position: right center;
	background-repeat: no-repeat;
	background-size: contain;
}

.bg-profile {
	background-image: url('/images/landing/bg-profile.png');
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
	background-blend-mode: color-dodge
}

.bg-why-secretty {
	background-image: url('/images/landing/why-secretty-bg.png');
	background-position: center -150px;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-be-secretty {
	background: url('/images/landing/join-us-bg-new.png') no-repeat center center;
	background-size: cover;
}

.bg-plataform {
	background: url('/images/landing/platform-bg-new.png') no-repeat center center;
	background-size: cover;
}

.bg-use-secretty {
	background: url('/images/landing/use-secretty.png') no-repeat center center;
	background-size: cover;
}

@media (max-width: 768px) {
	.bg-be-secretty {
		background: url('/images/landing/join-us-bg-new-mobile.png') no-repeat center center;
		background-size: cover;
	}

	.bg-landing {
		background: url('/images/landing/bg-hero-mobile.png') #070102 top center no-repeat;
		background-size: contain;
	}
}
