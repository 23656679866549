:root {
	--primary: #22c67b;
	--secondary: #25cfd2;
	--text-color: #797C86;
	--purple: #0A0A0A;
}

html {
	scroll-behavior: smooth !important;
}

img {
	display: block;
	line-height: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.bg-landing a:not([class*="font"], [class*="__variable"], [class*="className"]),
.bg-landing p:not([class*="font"], [class*="__variable"], [class*="className"]),
.bg-landing span:not([class*="font"], [class*="__variable"], [class*="className"]),
.bg-landing li:not([class*="font"], [class*="__variable"], [class*="className"]) {
	font-weight: 400;
}

.bunya-font {
	font-family: 'Bunya PERSONAL'
}

.mulish-font {
	font-family: "Mulish"
}

.slider-custom {
	-webkit-appearance: none;
	-moz-appearance: none;
	     appearance: none;
	width: 100%;
	height: 4px;
	background: transparent;
	outline: none;
}

.slider-custom::-webkit-slider-runnable-track {
	background: linear-gradient(
			to right,
			#db2b4a 0%,
			#db2b4a var(--slider-progress),
			rgba(255, 255, 255, 0.1) var(--slider-progress),
			rgba(255, 255, 255, 0.1) 100%
	);
	border-radius: 50px;
	height: 4px;
}

.slider-custom::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	background: #db2b4a;
	border: 4px solid #fff;
	border-radius: 100%;
	cursor: pointer;
	margin-top: -8px;
}

.slider-custom::-moz-range-track {
	background: linear-gradient(
			to right,
			#db2b4a 0%,
			#db2b4a var(--slider-progress),
			rgba(255, 255, 255, 0.19) var(--slider-progress),
			rgba(255, 255, 255, 0.1) 100%
	);
	border-radius: 50px;
	height: 4px;
}

.slider-custom::-moz-range-thumb {
	width: 20px;
	height: 20px;
	background: #db2b4a;
	border: 4px solid #fff;
	border-radius: 100%;
	cursor: pointer;
}

.slider-custom::-moz-focus-outer {
	border: 0;
}

input[type=number] {
	-moz-appearance: textfield;
	-webkit-appearance: textfield;
	        appearance: textfield
}

.bg-landing {
	background: url('/images/landing/bg-hero-1-new.png') #070102 top center no-repeat;
	background-size: cover;
}

.bg-bill {
	background: url('/images/landing/bg-bill-new.png') center center no-repeat;
	background-size: cover;
}

.bg-ideas {
	background-image: url('/images/landing/bg-ideas.png');
	background-position: right center;
	background-repeat: no-repeat;
	background-size: contain;
}

.bg-profile {
	background-image: url('/images/landing/bg-profile.png');
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
	background-blend-mode: color-dodge
}

.bg-why-secretty {
	background-image: url('/images/landing/why-secretty-bg.png');
	background-position: center -150px;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-be-secretty {
	background: url('/images/landing/join-us-bg-new.png') no-repeat center center;
	background-size: cover;
}

.bg-plataform {
	background: url('/images/landing/platform-bg-new.png') no-repeat center center;
	background-size: cover;
}

.bg-use-secretty {
	background: url('/images/landing/use-secretty.png') no-repeat center center;
	background-size: cover;
}

@media (max-width: 768px) {
	.bg-be-secretty {
		background: url('/images/landing/join-us-bg-new-mobile.png') no-repeat center center;
		background-size: cover;
	}

	.bg-landing {
		background: url('/images/landing/bg-hero-mobile.png') #070102 top center no-repeat;
		background-size: contain;
	}
}

/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_72d435';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Mulish_Fallback_72d435';src: local("Arial");ascent-override: 96.02%;descent-override: 23.89%;line-gap-override: 0.00%;size-adjust: 104.67%
}.__className_72d435 {font-family: '__Mulish_72d435', '__Mulish_Fallback_72d435';font-style: normal
}.__variable_72d435 {--font-mulish: '__Mulish_72d435', '__Mulish_Fallback_72d435'
}

/* vietnamese */
@font-face {
  font-family: '__Lexend_1a5c49';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d31e2d21246ecb7d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Lexend_1a5c49';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/dcd78bcc5ace0fe6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lexend_1a5c49';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cf6384938887ffe2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lexend_Fallback_1a5c49';src: local("Arial");ascent-override: 90.45%;descent-override: 22.61%;line-gap-override: 0.00%;size-adjust: 110.56%
}.__className_1a5c49 {font-family: '__Lexend_1a5c49', '__Lexend_Fallback_1a5c49';font-weight: 400;font-style: normal
}.__variable_1a5c49 {--font-lexend: '__Lexend_1a5c49', '__Lexend_Fallback_1a5c49'
}

